import gql from "graphql-tag";

export const INITIAL = false


// export const RELATED = false


export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    stitching_full_review(where: {id: {_eq: $id}}) {
        is_pred_good
        isLabelled
        id
        torso_seg
        torso_overlapped_seg
        lua_seg
        lua_overlapped_seg
        rua_seg
        rua_overlapped_seg
        background_seg
        lla_seg
        lla_overlapped_seg
        rla_seg
        rla_overlapped_seg
        person_id
        prediction_type
        torso_overlapped_orientation
        lua_overlapped_orientation
        rua_overlapped_orientation
        rla_overlapped_orientation
        lla_overlapped_orientation
        reason_for_na
        straps_seg
        straps_overlapped_seg
        collar_seg
        collar_overlapped_seg
        map_cloth{
          cloth_id
          map_tops_cloth_tool{
            id
          }
        }
        map_new_cloth{
          cloth_id
          map_tops_cloth_tool{
            id
          }
        }

    }
  }
`;


export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $torso_seg: String
    $lla_seg: String
    $rla_seg: String
    $lua_seg: String
    $rua_seg: String
    $background_seg: String
    $prediction_type: String
    $name: String
    $seconds_spent: Int
    $torso_overlapped_seg: String
    $lua_overlapped_seg: String
    $rua_overlapped_seg: String
    $lla_overlapped_seg: String
    $rla_overlapped_seg: String
    $collar_seg: String
    $collar_overlapped_seg: String
    $straps_seg: String
    $straps_overlapped_seg: String
  ) {
    update_stitching_full_review(
      where: { id: { _eq: $id } }
      _set: { 
          torso_seg: $torso_seg,
          lla_seg: $lla_seg,
          rla_seg: $rla_seg,
          lua_seg: $lua_seg,
          rua_seg: $rua_seg,
          background_seg: $background_seg,
          isLabelled: "Labelled",
          is_na: "No",
          reason_for_na: "No",
          prediction_type: $prediction_type,
          name: $name,
          seconds_spent: $seconds_spent
          torso_overlapped_seg: $torso_overlapped_seg
          lua_overlapped_seg: $lua_overlapped_seg
          rua_overlapped_seg: $rua_overlapped_seg
          lla_overlapped_seg: $lla_overlapped_seg
          rla_overlapped_seg: $rla_overlapped_seg
          straps_seg: $straps_seg
          straps_overlapped_seg: $straps_overlapped_seg
          collar_seg: $collar_seg
          collar_overlapped_seg: $collar_overlapped_seg
        }
    ) {
      affected_rows
    }
  }
`;

export const allParts = [
  {
    variable : "torso_seg",
    color: "rgba(255,85,0,255)",
    // color: "#33aadd",
    name: "TORSO",
    main_class: {width: "300px"}
  },
  {
    variable : "torso_overlapped_seg",
    color: "rgba(255,85,0,255)",
    // color: "#33aadd",
    name: "TORSO ON TOP",
    main_class: {color: "white",width: "300px"},
    classes: {color: "white"},
    radio: true
    // main_class: {width: "140px"}
  },

  {
    variable : "collar_seg",
    color: "rgba(123,56,67,255)",
    classes: {color: "white"},
    // color: "#33aadd",
    name: "COLLAR",
    main_class: {width: "300px"}
  },
  {
    variable : "collar_overlapped_seg",
    color: "rgba(123,56,67,255)",
    // color: "#33aadd",
    name: "COLLAR ON TOP",
    main_class: {color: "white",width: "300px"},
    classes: {color: "white"},
    radio: true
    // main_class: {width: "140px"}
  },


  // {
  //   variable : "straps_overlapped_seg",
  //   color: "rgba(255, 85, 255,255)",
  //   // color: "#33aadd",
  //   name: "STRAPS ON TOP",
  //   main_class: {color: "white",width: "300px"},
  //   classes: {color: "white"},
  //   radio: true
  //   // main_class: {width: "140px"}
  // },
  {
    variable: "rua_seg",
    color: "rgba(57,255,20,255)",
    name: "LEFT UPPER ARM",
    main_class: {width: "300px"},
  },
  {
    variable : "lua_seg",
    color: "rgba(0,0,128,255)",
    name: "RIGHT UPPER ARM",
    main_class: {color: "white"},
    classes: {color: "white"},
    main_class: {width: "300px"},
  },
  {
    variable: "rua_overlapped_seg",
    color: "rgba(57,255,20,255)",
    name: "LEFT UPPER ARM ON TOP",
    radio: true
  },
  {
    variable : "lua_overlapped_seg",
    color: "rgba(0,0,128,255)",
    name: "RIGHT UPPER ARM ON TOP",
    main_class: {color: "white"},
    classes: {color: "white"},
    radio: true
  },
  {
    variable: "rla_seg",
    color: "rgba(170,0,118,255)",
    name: "LEFT LOWER ARM",
    main_class: {color: "white", width: "300px"},
    classes: {color: "white"},
  },
  {
    variable : "lla_seg",
    color: "rgba(0,150,136,255)",
    name: "RIGHT LOWER ARM",
    main_class: {width: "300px"},
  },
  {
    variable: "rla_overlapped_seg",
    color: "rgba(170,0,118,255)",
    name: "LEFT LOWER ARM ON TOP",
    main_class: {color: "white"},
    classes: {color: "white"},
    radio: true
  },
  {
    variable : "lla_overlapped_seg",
    color: "rgba(0,150,136,255)",
    name: "RIGHT LOWER ARM ON TOP",
    radio: true
  },
  {
    variable : "torso_only_seg",
    color: "rgba(255,85,0,255)",
    name: "TORSO ONLY",
    main_class: {color: "white",width: "300px", marginTop: "10px" },
    classes: {color: "white"},
    radio: true
  },

  {
    variable : "collar_only_seg",
    color: "rgba(123,56,67,255)",
    name: "COLLAR ONLY",
    main_class: {color: "white",width: "300px", marginTop: "10px" },
    classes: {color: "white"},
    radio: true
  },
  // {
  //   variable : "straps_only_seg",
  //   color: "rgba(255, 85, 255,255)",
  //   name: "STRAPS ONLY",
  //   main_class: {color: "white",width: "300px", marginTop: "10px" },
  //   classes: {color: "white"},
  //   radio: true
  // },

  {
    variable : "rua_only_seg",
    color: "rgba(57,255,20,255)",
    name: "LEFT UPPER ARM ONLY",
    radio: true
  },
  {
    variable : "lua_only_seg",
    color: "rgba(0,0,128,255)",
    name: "RIGHT_UPPER_ARM ONLY"
  },
  {
    variable: "rla_only_seg",
    color: "rgba(170,0,118,255)",
    name: "LEFT LOWER ARM ONLY",
    main_class: {color: "white"},
    classes: {color: "white"},
    radio: true
  },

  {
    variable: "lla_only_seg",
    color: "rgba(0,150,136,255)",
    name: "RIGHT LOWER ARM ONLY",
    main_class: {color: "black"},
    classes: {color: "black"},
  },
  // {
  //   variable : "straps_seg",
  //   color: "rgba(255, 85, 255,255)",
  //   // color: "#33aadd",
  //   name: "STRAPS",
  //   // main_class: {width: "140px"}s
  // },

]

export const viewParts = {
  "torso_seg": ["rua_seg", "rla_seg", "lua_seg", "lla_seg"],
  "rua_seg": ["rla_seg","torso_seg"],
  "rla_seg": ["rua_seg","torso_seg"],
  "lla_seg": ["lua_seg", "torso_seg"],
  "lua_seg": ["lla_seg","torso_seg"],
  "collar_seg": ["torso_seg"]
}


export const PRED_GOOD_AVAILABLE = false

export const TABLE = "stitching_full_review"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const NEW_PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/stitching/`

export const PREDICTION_EXT = `.png`

export const UPDATE_PRED_GOOD = gql`mutation MyMutation($id: Int, $is_pred_good: String, $isLabelled: String) {
  update_stitching_full_review(where: {id: {_eq: $id}}, _set: {is_pred_good: $is_pred_good, isLabelled: $isLabelled}) {
    affected_rows
  }
}`;

export const RELATED = false

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  stitching_full_review_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!
  ){
      update_stitching_full_review(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;