import React from 'react';
import { Button } from "@chakra-ui/core";

export function renderButtons(part,index, addAnotherPath,person_data, TABLE, activeVariable){
    return (<div style={{ ...part["main_class"] }}>
      <Button
        onClick={() =>
          addAnotherPath(
            part["color"],
            part["color"],
            part["color"],
            person_data[TABLE][0][part["variable"]],
            part["variable"]
          )
        }
        style={{
          margin: "3px 0px 0px 0px",
          backgroundColor: part["color"],
          border:
            activeVariable == part["variable"]
              ? "5px solid black"
              : "",
          ...part["classes"],
        }}
      // className={part["classes"]}
      >
        <div>
          {part["name"]}
          {part["extra_info"] ? (
            <div>{part["extra_info"]}</div>
          ) : null}
        </div>
      </Button>
      {/* </RadioGroup>} */}
      {part["desc"] && (
        <div>
          <b>{part["desc"]}</b>
        </div>
      )}
    </div>)
  }