import React from 'react';
import { Button, RadioGroup, Radio } from "@chakra-ui/core";
import Select from "react-select"
import "./controls.css";

const Controls = (props) => {
    const {
        renderButtons,
        mode,
        allParts,
        addAnotherPath,
        person_data,
        TABLE,
        activeVariable,
        points,
        activeIndex,
        deletePointClick,
        PRED_GOOD_AVAILABLE,
        markAsGood,
        lastPointClick,
        setMode,
        onBackgroundClick,
        onSave,
        opacity,
        setOpacity,
        nextImage,
        fullViewRef,
        lastPoint,
        ignorePart,
        setIgnorePart,
        allOptions,
        setIgnorePartActive,
        deleteSeg,
        deleteSegOne
    } = props;

    const checkConnectionAndFillWithSome = () => {
        if (points[activeIndex - 1].length > 2)
          if (points[activeIndex - 1][0] == lastPoint) {
            return (
              // <Button className="" onClick={fillRest}>
              <div>
                <div style={{ width: "300px", whiteSpace: "break-spaces" }}>
                  IGNORE
                </div>
                <div style={{ width: "300px", whiteSpace: "break-spaces" }}>
                  <Select
                    value={ignorePart}
                    onChange={(e) => { console.log(e); setIgnorePart(e) }}
                    isMulti={true}
                    options={allOptions}
                  >
                  </Select>
                </div>
                <Button onClick={() => { setIgnorePartActive(true) }}>
                  DONE
                </Button>
              </div>
            );
          }
      };

    return (
        <div style={{ width: "600px" }}>

            <div
                style={{ margin: "0px 5px", backgroundColor: "aliceblue" }}
            >
                <div>
                    <div>MODE</div>
                    {
                        <RadioGroup
                            onChange={(e) => setMode(e.target.value)}
                            value={mode}
                            style={{ display: "flex", padding: "0px 5px" }}
                        >
                            <Radio value={"full"} style={{ margin: "0px 5px" }}>
                                FULL
                            </Radio>
                            <Radio
                                value={"overlapped"}
                                style={{ margin: "0px 5px" }}
                            >
                                OVERLAPPED
                            </Radio>
                        </RadioGroup>
                    }
                </div>
                <div style={{ display: "flex" }}>
                    <Button onClick={() => setOpacity(opacity + 1)}>+</Button>
                    <div style={{ margin: "0px 10px" }}>
                        <b>{opacity}</b>
                    </div>
                    <Button onClick={() => setOpacity(opacity - 1)}>-</Button>

                    <Button
                        onClick={onSave}
                        style={{ margin: "0px 0px 0px 40px", backgroundColor: "lightgreen" }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => {
                            nextImage()
                        }}
                        style={{ margin: "0px 0px 0px 40px" }}
                    >
                        Next{" "}
                    </Button>
                </div>
            </div>
            {deleteSeg()}
            <div style={{ padding: "20px", width: "600px" }}>
                {points[activeIndex].length > 0 &&  checkConnectionAndFillWithSome()}
                {points[activeIndex].length > 0 && (
                    <div>
                        <Button
                            className="margin--10px"
                            style={{ height: "40px" }}
                            onClick={lastPointClick}
                        >
                            Connect to first point
                        </Button>
                    </div>
                )}
                {points[activeIndex].length > 0 && (
                    <div>
                        <Button
                            className="margin--10px"
                            style={{ height: "40px" }}
                            onClick={deletePointClick}
                        >
                            Delete last point
                        </Button>
                    </div>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                {allParts.map((part, index) => {
                    if (
                        mode == "full" &&
                        (!part["variable"].includes("_overlapped") && !(part["variable"].includes("_only")))
                    ) {
                        return renderButtons(part, index, addAnotherPath, person_data, TABLE, activeVariable)
                    }
                    if (
                        mode == "overlapped" &&
                        ((part["variable"].includes("_overlapped")) || (part["variable"].includes("_only")))
                    ) {
                        return renderButtons(part, index, addAnotherPath, person_data, TABLE, activeVariable)
                    }
                })}
            </div>

            <Button
                onClick={onBackgroundClick}

            >
                Background
            </Button>

            <div>
                <div class="outer">
                    <div class="canvas_1">
                        <canvas
                            height="400px"
                            width="300px"
                            ref={fullViewRef}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Controls