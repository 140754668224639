import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Radio, RadioGroup, Button, Select } from "@chakra-ui/core";
import mergeImages from "merge-images";
import Controls from "./Controls"
import moment from "moment";
import {
  linkChange_id,
  getTensorFromImage,
  resize,
  putImageDataCanvas,
  predictionOnload,
  createFinalPrediction,
  imageToTensor,
  imageToTensorNew,
  createOverlapRegin,
  addToCanvas
} from "../utils/utility";
import {
  transformArraytoObject,
  loadImage,
  create_full,
  make_rest_transparent,
  create_overlap_region,
  processOnlyPoints,
  showFull,
  removeBackground,
  processOverlappPoints,
  useKeyPress,
  create_data_url_from_image_object,
  filterIgnoreParts
} from "../utility/helper-functions"
import {
  allParts,
  viewParts,
  UPDATE_PERSON,
  TABLE,
  PRED_GOOD_AVAILABLE,
  OUTPUT_BACKGROUND,
  OUTPUT_BACKGROUND_EXT,
  GET_IMAGE_URL,
  UPDATE_PERSON_NA,
  UPDATE_PRED_GOOD,
  INITIAL,
} from "../main";

import "./index.css";
import WorkArea from "./WorkArea"
import Review from "./ReviewWork"
import { renderButtons } from "./ui"
import BlackImage from "../../images/data-dumps_black-800x600.png"
let SCALE = 1;
const OFFSET_X = 0;
const OFFSET_Y = 0;
const SIZE = 600;
let SIZEX = 600;
let SIZEY = 800;
let RATIO = SIZEX / SIZEY;
let imageObj;
let LEFT_POSITION = "1700px";
let TOP_POSITION = "60px";

function createImage(ctx, list, opacitySeg = 5) {
  let newlist = [];
  for (let i = 0; i < list.length; i += 1) {
    if (list[i] !== null && list[i] !== undefined) {
      newlist.push({
        src: list[i],
        opacity: 0.1 * opacitySeg,
      });
    }
  }
  mergeImages(newlist).then(async (b64) => {
    await make_base(ctx, b64);
  });
}

function make_base(ctx, src) {
  let base_image = new Image();
  base_image.src = src;
  base_image.onload = function () {
    URL.revokeObjectURL(base_image.src);
    ctx.drawImage(base_image, 0, 0, SIZEX, SIZEY);
  };
}

const MapPoints = (props) => {
  const { id, nextImage } = props;
  const [mutateType, setMutateType] = useState("saved");
  const workingCanvasRef = useRef()
  const [points, setPoints] = useState([[]]);
  const [pointsColor, setPointsColor] = useState([
    { colorSave: "rgba(255,85,0,255)", colorView: "rgba(255,85,0,255)", colorPreview: "rgba(255,85,0,255)", variable: "torso_seg" },
  ]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [lastPoint, setLastPoint] = useState({});
  const [penultimatePoint, setPenultimatePoint] = useState({});
  const [activeVariable, setActiveVariable] = useState("torso_seg");
  const [imageToMerge, setImageToMerge] = useState({});
  const [showOneSeg, setShowOneSeg] = useState("Yes");
  const [opacity, setOpacity] = useState(7);
  const [opacitySeg, setOpacitySeg] = useState(6);
  const [mode, setMode] = useState("full");

  const [allLoading, setAllLoading] = useState(true)
  const [predictionOfAll, setPredictionOfAll] = useState({})
  const [allPredictionArray, setAllPredictionArray] = useState([])
  const [full, setFull] = useState(null)
  const [overlapRegion, setOverlapRegion] = useState(null)
  const helperCanvas = useRef()
  const onlySegCanvas = useRef()
  const fullRef = useRef()
  const backgroundCanvas = useRef()
  const overlappedSegCanvas = useRef()
  const overlapRegionCanvas = useRef()
  const [onlyProcessed, setOnlyProcessed] = useState(false)
  const [backgroundProcessed, setBackgroundProcessed] = useState(false)
  const [backgroundClick, setBackgroundClick] = useState(false)
  const [onTopSeg, setOnTopSeg] = useState({})
  const [backgroundOneSeg, setBackgroundOneSeg] = useState(false)
  const segmentationsRegionCanvas = useRef()
  const fullViewRef = useRef()
  const connectToFIrstPointKeyDown = useKeyPress("c");
  const deleteLastPointKeyDown = useKeyPress("d");
  const opacityIncreaseKeyDown = useKeyPress("+");
  const opacityDecreaseKeyDown = useKeyPress("-");
  const saveKeyDown = useKeyPress("s")
  const [initTime, setInitTime] = useState(moment());
  const [ignorePart, setIgnorePart] = useState([])
  const [ignorePartActive, setIgnorePartActive] = useState(false)

  useEffect(() => {
    if(points[activeIndex].length > 0 && connectToFIrstPointKeyDown){
      lastPointClick()
    }
  }, [connectToFIrstPointKeyDown])

  useEffect(() => {
    if(points[activeIndex].length > 0 && deleteLastPointKeyDown){
      deletePointClick()
    }
  }, [deleteLastPointKeyDown])
  useEffect(() => {
    setOpacity(opacity + 1)
  }, [opacityIncreaseKeyDown])
  useEffect(() => {
    setOpacity(opacity - 1)
  }, [opacityDecreaseKeyDown])

  const onBackgroundClick = () => {
    setBackgroundClick(true)
  }


  const lastPointClick = () => {
    if (activeVariable.indexOf("_only") != -1) {
      processOnlyPoints(points, pointsColor, activeIndex, overlapRegion, setOverlapRegion, predictionOfAll, setPredictionOfAll, onlySegCanvas, () => setOnlyProcessed(!onlyProcessed))
    }
    if(backgroundClick){
      setBackgroundOneSeg(!backgroundOneSeg)
    }
    let temp = points;
    let tempPoints = points[activeIndex];
    tempPoints = [...tempPoints, tempPoints[0]];
    temp[activeIndex] = tempPoints;
    temp.push([[]]);
    let tempColors = pointsColor;
    tempColors = [...tempColors, pointsColor[activeIndex]];
    setPoints(temp);
    setPointsColor(tempColors);
    setLastPoint(points[activeIndex][0]);
    setActiveIndex(activeIndex + 1);
  };

  const capturePoint = (evt) => {
    if (activeIndex == 0) {
      alert("Please select the body part that you want to color");
      return;
    }
    const mousePos = getMousePos(workingCanvasRef, evt);
    const temp = points;
    temp[activeIndex].push(mousePos);
    setPoints(temp);
    setPenultimatePoint(lastPoint);
    setLastPoint(mousePos);
  };

  const addAnotherPath = (
    save,
    view,
    preview,
    part = null,
    variable = null
  ) => {
    if(backgroundClick){
      setBackgroundClick(false)
    }
    setActiveVariable(variable);
    if (person_data && person_data[TABLE][0][variable]) {
      setImageToMerge({ ...imageToMerge, [variable]: "save" });
    } else {
      setImageToMerge({ ...imageToMerge, [variable]: "scratch" });
    }
    const temp = points;
    temp.push([]);
    const tempColor = pointsColor;
    tempColor.push({
      colorSave: save,
      colorView: view,
      colorPreview: preview,
      variable: variable
    });
    setActiveIndex(activeIndex + 1);
    setPenultimatePoint({});
    setLastPoint({});
    setPointsColor(tempColor);
    setPoints(temp);
  };

  function getMousePos(workingCanvasRef, evt) {
    const rect = workingCanvasRef.current.getBoundingClientRect();
    return {
      x: evt.clientX - rect.x,
      y: evt.clientY - rect.y,
    };
  }

  const { loading, data: person_data } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: id,
    },
    onCompleted(data) {
      // setLoadOnce(true);
    },
  });

  const [save_all, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_PERSON, {
      onCompleted(data) {
        if (mutateType == "saved") {
          window.location.reload();
        }
      },
      onError(data) {
        alert(
          `Your segmentations did not save. Please check your internet connection.\n For technical purpose: ${data} `
        );
      },
      refetchQueries: [
        {
          query: GET_IMAGE_URL,
          variables: {
            id: id,
          },
        },
      ],
    });

  /*
    setting width and height of canvas
  */
  useEffect(() => {
    if (!allLoading) {
      let ctx = workingCanvasRef.current.getContext("2d");
      workingCanvasRef.current.style.width = SIZEX + "px";
      workingCanvasRef.current.style.height = SIZEY + "px";
      var scale = 1;
      workingCanvasRef.current.width = Math.floor(SIZEX * scale);
      workingCanvasRef.current.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);
    }
  }, [SIZEX, SIZEY, allLoading]);

  /* 
    Load prediction and saved data
  */

  useEffect(() => {

    if (person_data && person_data[TABLE][0]["person_id"]) {
      let allPartsObj = transformArraytoObject(allParts, "variable")
      let preloadImagesArray = []
      let overlappedSegList = []

      Object.keys(allPartsObj).forEach((key) => {
        if (key.indexOf("_only") == -1 && key.indexOf("_overlapped") == -1 && key.indexOf("background") == -1) {
          let name = key.split("_")[0]
          if (person_data[TABLE][0][key] == null) {
            if (key.indexOf("collar") == -1) {
              preloadImagesArray.push({ "src": `https://storage.googleapis.com/download/storage/v1/b/labelling-tools-data/o/data_dumps%2Ftorso_arm_pred%2F${name}%2F${person_data[TABLE][0]["person_id"]}.png?alt=media`, name: key })
            } else {
              preloadImagesArray.push({ "src": BlackImage, name: key })
            }
          } else {
            preloadImagesArray.push({ "src": person_data[TABLE][0][key], name: key })
          }
        }

        else if (key.indexOf("_overlapped") != -1){
          overlappedSegList.push(person_data[TABLE][0][key])
        }
      })

      // setAllPredictionArray(preloadImagesArray)
      let allPredictionObj = transformArraytoObject(preloadImagesArray, "name")
      const load = async (u) => {
        const img = await loadImage(u['src'])
        return img
      }
      let torso = load(allPredictionObj["torso_seg"])
      let lla = load(allPredictionObj["lla_seg"])
      let lua = load(allPredictionObj["lua_seg"])
      let rla = load(allPredictionObj["rla_seg"])
      let rua = load(allPredictionObj["rua_seg"])
      let collar = load(allPredictionObj["collar_seg"])

      Promise.all([torso, lla, lua, rla, rua, collar]).then(data => {
        let predForAll = { "torso_seg": data[0], "lla_seg": data[1], "rla_seg": data[3], "rua_seg": data[4], "lua_seg": data[2], "collar_seg": data[5] }
        setFull(create_full(predForAll))
        setPredictionOfAll(predForAll)
      })

    }




  }, [person_data])

  useEffect(() => {
    if(person_data && person_data[TABLE][0]){
      let allPartsObj = transformArraytoObject(allParts, "variable")
      let overlappedSegList = []

      Object.keys(allPartsObj).forEach((key) => {
        if (key.indexOf("_overlapped") != -1){
          overlappedSegList.push(person_data[TABLE][0][key])
        }
      })

      if(segmentationsRegionCanvas && segmentationsRegionCanvas.current){
        let ctx = segmentationsRegionCanvas.current.getContext("2d");
        segmentationsRegionCanvas.current.style.width = SIZEX + "px";
        segmentationsRegionCanvas.current.style.height = SIZEY + "px";
        var scale = 1;
        segmentationsRegionCanvas.current.width = Math.floor(SIZEX * scale);
        segmentationsRegionCanvas.current.height = Math.floor(SIZEY * scale);
        ctx.scale(scale, scale);
      
        createImage(ctx, overlappedSegList, 10)
      }
    }
  }, [person_data, segmentationsRegionCanvas.current])

  useEffect(() => {
    if (predictionOfAll) {
      console.log(predictionOfAll)
      if (Object.keys(predictionOfAll).length >= 6) {
        if (allLoading) {
          setAllLoading(false)
        }
      }
    }
    console.log("is in predfor all useEffect")
    setFull(create_full(predictionOfAll))
  }, [predictionOfAll, onlyProcessed, backgroundOneSeg])

  useEffect(() => {
    showFull(full, fullRef)
    showFull(full, fullViewRef, 300, 400, false)
    setOverlapRegion(create_overlap_region(full, allParts, predictionOfAll))
  }, [full, onlyProcessed])

  useEffect(() => {
    showFull(overlapRegion, overlapRegionCanvas)
  },  [overlapRegion])


  const clear_canvas = (canvasRef) => {
    if (canvasRef && canvasRef.current) {
      console.log("clear canvas")
      let ctx = canvasRef.current.getContext('2d')
      ctx.clearRect(0, 0, 600, 800)
    }
  }

  useEffect(() => {
    setOverlapRegion(create_overlap_region(full, allParts, predictionOfAll))
    clear_canvas(workingCanvasRef)
    clear_canvas(helperCanvas)
    // if(mode != "overlapped"){
    //   setActiveVariable("torso_seg")
    // }
    if (mode == "overlapped") {
      setPoints([[]]);
      setPointsColor([
        { colorSave: "rgba(255,85,0,255)", colorView: "rgba(255,85,0,255)", colorPreview: "rgba(255,85,0,255)", variable: "torso_overlapped_seg" },
      ]);
      setActiveIndex(0)
      setLastPoint({});
      setPenultimatePoint({});
      setActiveVariable("torso_overlapped_seg");
    }
  }, [mode])

  useEffect(() => {

    if (person_data && !allLoading && activeVariable !== "background_seg" && activeVariable.indexOf("_overlapped") == -1 && activeVariable.indexOf("_only") == -1) {

      let ctx = helperCanvas.current.getContext("2d");
      helperCanvas.current.style.width = SIZEX + "px";
      helperCanvas.current.style.height = SIZEY + "px";
      var scale = 1;
      helperCanvas.current.width = Math.floor(SIZEX * scale);
      helperCanvas.current.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);
      ctx.clearRect(0, 0, helperCanvas.current.width, helperCanvas.current.height);

      if (activeVariable !== "background_seg" && activeVariable.indexOf("_overlapped") == -1 && activeVariable.indexOf("_only") == -1) {
        let empty = ctx.getImageData(0, 0, 600, 800);
        for (let i = 0; i <viewParts[activeVariable].length;i += 1){
          let tempImageData = make_rest_transparent(predictionOfAll[viewParts[activeVariable][i]].data, opacity*0.1/2)
          for (let j = 0; j < empty.data.length; j += 4){
            if(tempImageData[j+3]!=0){
              empty.data[j] = tempImageData[j]
              empty.data[j+1] = tempImageData[j+1]
              empty.data[j+2] = tempImageData[j+2]
              empty.data[j+3] = Math.floor((opacity*0.1/2)*255)
            }
          }
        }
        ctx.putImageData(empty, 0, 0);
      }

      ctx = workingCanvasRef.current.getContext("2d");
      workingCanvasRef.current.style.width = SIZEX + "px";
      workingCanvasRef.current.style.height = SIZEY + "px";
      var scale = 1;
      workingCanvasRef.current.width = Math.floor(SIZEX * scale);
      workingCanvasRef.current.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);
      ctx.clearRect(0, 0, workingCanvasRef.current.width, workingCanvasRef.current.height);



      // if overlaped or only region is not selected show the prediction
      if (activeVariable !== "background_seg" && activeVariable.indexOf("_overlapped") == -1 && activeVariable.indexOf("_only") == -1) {
        // ctx.globalAlpha = 0.5;
        // alert("comes")
        make_rest_transparent(predictionOfAll[activeVariable].data, opacity*0.1)
        ctx.putImageData(predictionOfAll[activeVariable], 0, 0);
      } else {
        make_rest_transparent(overlapRegion.data, opacity*0.1)
        ctx.putImageData(overlapRegion, 0, 0);
      }
      if (!backgroundClick) {
        ctx.globalAlpha = 0.7;
        ctx.fillStyle = pointsColor[activeIndex].colorPreview;
        ctx.strokeStyle = pointsColor[activeIndex].colorPreview;
        if (activeIndex > 0) {
          for (let j = 0; j < points.length - 1; j++) {
            if (pointsColor[j] == pointsColor[activeIndex]) {
              ctx.beginPath();
              if (points[j].length > 0) {
                ctx.moveTo(points[j][0].x, points[j][0].y);
              }
              for (let i = 1; i < points[j].length; i++) {
                ctx.fillStyle = pointsColor[activeIndex].colorPreview;
                ctx.fillRect(
                  points[j][i].x - OFFSET_X,
                  points[j][i].y - OFFSET_Y,
                  1,
                  1
                );
                ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
              }
              ctx.lineWidth = 1;
              ctx.globalAlpha = 0.7;
              ctx.fillStyle = pointsColor[j].colorView;
              ctx.fill();
              // ctx.stroke();
              if (activeVariable !== "background_seg" && activeVariable.indexOf("_overlapped") == -1 && activeVariable.indexOf("_only") == -1) {
                let imageData = ctx.getImageData(0, 0, 600, 800)
                console.log("Ignore part", ignorePart)
                setPredictionOfAll({ ...predictionOfAll, [activeVariable]: imageData })
              }
            }
          }
        }
        if (penultimatePoint) {
          ctx.globalAlpha = 0.7;
          ctx.beginPath();
          if (points[activeIndex].length > 0) {
            ctx.moveTo(points[0].x, points[0].y);
            ctx.fillRect(
              points[activeIndex][0].x - OFFSET_X,
              points[activeIndex][0].y - OFFSET_Y,
              3,
              3
            );
          }
          for (let i = 0; i < points[activeIndex].length; i++) {
            ctx.fillRect(
              points[activeIndex][i].x - OFFSET_X,
              points[activeIndex][i].y - OFFSET_Y,
              3,
              3
            );
            ctx.lineTo(
              points[activeIndex][i].x - OFFSET_X,
              points[activeIndex][i].y - OFFSET_Y
            );
          }
          ctx.stroke();
        }
      }else{
        ctx = backgroundCanvas.current.getContext("2d");
        backgroundCanvas.current.style.width = SIZEX + "px";
        backgroundCanvas.current.style.height = SIZEY + "px";
        var scale = 1;
        backgroundCanvas.current.width = Math.floor(SIZEX * scale);
        backgroundCanvas.current.height = Math.floor(SIZEY * scale);
        ctx.scale(scale, scale);
        ctx.clearRect(0, 0, backgroundCanvas.current.width, backgroundCanvas.current.height);

        ctx.fillStyle = "rgba(204,204,204,255)"
        ctx.strokeStyle = "rgba(204,204,204,255)"
        if (activeIndex > 0) {
          for (let j = points.length - 1; j < points.length; j++) {
            if (pointsColor[j] == pointsColor[activeIndex]) {
              ctx.beginPath();
              if (points[j].length > 0) {
                ctx.moveTo(points[j][0].x, points[j][0].y);
              }
              for (let i = 1; i < points[j].length; i++) {
                ctx.fillStyle = "rgba(204,204,204,255)"
                ctx.fillRect(
                  points[j][i].x - OFFSET_X,
                  points[j][i].y - OFFSET_Y,
                  1,
                  1
                );
                ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
              }
              ctx.lineWidth = 1;
              ctx.globalAlpha = 1;
              ctx.fillStyle = "rgba(204,204,204,255)"
              ctx.fill();
              let imageData = ctx.getImageData(0, 0, 600, 800)
              removeBackground(imageData, predictionOfAll, activeVariable,allParts, setPredictionOfAll, () => setBackgroundProcessed(!backgroundProcessed))
              // setBackgroundClick(false)
              // clear_canvas(backgroundCanvas)
              // setPredictionOfAll({ ...predictionOfAll, [activeVariable]: imageData })
              // ctx.stroke();
            }
          }
        }
        if (penultimatePoint) {
          ctx.beginPath();
          if (points[activeIndex].length > 0) {
            ctx.moveTo(points[0].x, points[0].y);
            ctx.fillRect(
              points[activeIndex][0].x - OFFSET_X,
              points[activeIndex][0].y - OFFSET_Y,
              3,
              3
            );
          }
          for (let i = 0; i < points[activeIndex].length; i++) {
            ctx.fillRect(
              points[activeIndex][i].x - OFFSET_X,
              points[activeIndex][i].y - OFFSET_Y,
              3,
              3
            );
            ctx.lineTo(
              points[activeIndex][i].x - OFFSET_X,
              points[activeIndex][i].y - OFFSET_Y
            );
          }
          ctx.stroke();
        }
      }
    }
  }, [
    lastPoint,
    activeVariable,
    imageToMerge,
    person_data,
    opacity,
    opacitySeg,
    // ignorePartActive
  ]);

  useEffect(() => {
    if(ignorePartActive){
    let tempCanvas = document.createElement('canvas');
    let ctx = tempCanvas.getContext("2d");
    tempCanvas.style.width = SIZEX + "px";
    tempCanvas.style.height = SIZEY + "px";
    var scale = 1;
    tempCanvas.width = Math.floor(SIZEX * scale);
    tempCanvas.height = Math.floor(SIZEY * scale);
    ctx.scale(scale, scale);
    ctx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
    ctx.fillStyle = pointsColor[activeIndex].colorPreview;
    ctx.strokeStyle = pointsColor[activeIndex].colorPreview;
    if (activeIndex > 0) {
      for (let j = 0; j < points.length - 1; j++) {
        if (pointsColor[j] == pointsColor[activeIndex]) {
          ctx.beginPath();
          if (points[j].length > 0) {
            ctx.moveTo(points[j][0].x, points[j][0].y);
          }
          for (let i = 1; i < points[j].length; i++) {
            ctx.fillStyle = pointsColor[activeIndex].colorPreview;
            ctx.fillRect(
              points[j][i].x - OFFSET_X,
              points[j][i].y - OFFSET_Y,
              1,
              1
            );
            ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
          }
          ctx.lineWidth = 1;
          ctx.globalAlpha = 1;
          ctx.fillStyle = pointsColor[j].colorView;
          ctx.fill();
          // ctx.stroke();
          if (activeVariable !== "background_seg" && activeVariable.indexOf("_overlapped") == -1 && activeVariable.indexOf("_only") == -1) {
            let imageData = ctx.getImageData(0, 0, 600, 800)
            // console.log(tempCanvas.toDataURL());
            let consoleCanvas = document.createElement('canvas');
            let consoleContext = consoleCanvas.getContext("2d");
            consoleCanvas.style.width = SIZEX + "px";
            consoleCanvas.style.height = SIZEY + "px";
            var scale = 1;
            consoleCanvas.width = Math.floor(SIZEX * scale);
            consoleCanvas.height = Math.floor(SIZEY * scale);
            let currentImageData = predictionOfAll[activeVariable]
            console.log(currentImageData)
            consoleContext.putImageData(currentImageData, 0, 0, 0,0,600, 800);
            console.log(consoleCanvas.toDataURL(),  "FULL")
            for (let i = 0; i < currentImageData.data.length; i += 4) {
              currentImageData.data[i] = imageData.data[i] ? 0: currentImageData.data[i]
              currentImageData.data[i+1] = imageData.data[i+1] ? 0: currentImageData.data[i+1]
              currentImageData.data[i + 2] = imageData.data[i+2] ? 0: currentImageData.data[i+2]
              currentImageData.data[i+3] = imageData.data[i+3] ? 0: currentImageData.data[i+3]
            }
            
            consoleContext.putImageData(currentImageData, 0, 0,0,0, 600, 800);
            console.log(consoleCanvas.toDataURL(), "Complete empty")
            console.log("Ignore part", ignorePart)
            console.log(tempCanvas.toDataURL(),"Before image data");
            filterIgnoreParts(currentImageData, imageData, ignorePart, predictionOfAll)
            console.log(tempCanvas.toDataURL(),"After image data");
            consoleContext.putImageData(currentImageData, 0, 0, 0,0,600, 800);
            // console.log(tempCanvas.toDataURL());
            console.log(consoleCanvas.toDataURL(), "Only required")
            setPredictionOfAll({ ...predictionOfAll, [activeVariable]: currentImageData })
            const temp = points;
            // console.log(points, 'before')
            let firstPop = temp.pop();
            let second = temp.pop();
            console.log("Pops", firstPop, second);
            temp.push([[]]);
            // console.log(temp.length, 'all_points')
            const tempColor = pointsColor;
            let color = tempColor.pop();
            console.log(
              pointsColor[pointsColor.length - 1],
              pointsColor.length,
              temp.length,
              "final_save"
            );
            // alert(pointsColor[pointsColor.length])
            setPenultimatePoint({});
            setLastPoint({});
            setPointsColor(tempColor);
            setPoints(temp);
            setActiveIndex(activeIndex - 1);
            setIgnorePart([])
            setIgnorePartActive(false)

          }
        }
      }
    }
    }
  }, [ignorePartActive])

  useEffect(() => {

    if (person_data && !allLoading && (activeVariable == "background_seg" || activeVariable.indexOf("_overlapped") != -1 || activeVariable.indexOf("_only") != -1)) {
      let ctx = helperCanvas.current.getContext("2d");
      helperCanvas.current.style.width = SIZEX + "px";
      helperCanvas.current.style.height = SIZEY + "px";
      var scale = 1;
      helperCanvas.current.width = Math.floor(SIZEX * scale);
      helperCanvas.current.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);

      make_rest_transparent(overlapRegion.data, opacity*0.1)
      ctx.putImageData(overlapRegion, 0, 0);
      // let tempOverlapRegion = ctx.getImageData(0, 0, 600, 800)
      ctx = workingCanvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, 600, 800)
      if (activeVariable.indexOf("_only") != -1) {
        processOnlyPoints(points, pointsColor, activeIndex, overlapRegion, setOverlapRegion, predictionOfAll, setPredictionOfAll, onlySegCanvas, () => setOnlyProcessed(true))
      }
      if (activeVariable.indexOf("_overlapped") != -1) {
        console.log("overlap on top")
        processOverlappPoints(points, pointsColor, activeIndex, penultimatePoint, onTopSeg, setOnTopSeg, overlappedSegCanvas)
      }
    }
  }, [
    lastPoint,
    pointsColor,
    activeVariable,
    imageToMerge,
    person_data,
    opacity,
    opacitySeg,
    overlapRegion,
    onlyProcessed,
    predictionOfAll
  ]);

  useEffect(() => {
    // alert("overlap region changes")
  }, [overlapRegion])

  const deletePointClick = () => {
    const temp = points;
    if (points[activeIndex].length === 1) {
      if (activeIndex > 0) {
        const temp = points.slice(0, points.length - 1);
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        // setToggle(false);
        setPoints([...temp]);
        setPointsColor([...tempColor]);
        setLastPoint(
          points[activeIndex - 1][points[activeIndex - 1].length - 1]
        );
        setActiveIndex(activeIndex - 1);
        // setUpdate(!update);
      } else {
        const tempColor = pointsColor.slice(0, pointsColor.length - 1);
        setActiveIndex(0);
        setPoints([[]]);
        setPointsColor([...tempColor]);
        // setToggle(false);
        setLastPoint({});
      }
    } else {
      const tempPoints = points[activeIndex].slice(
        0,
        points[activeIndex].length - 1
      );
      temp[activeIndex] = tempPoints;
      // setToggle(false);
      setPoints([...temp]);
      setLastPoint(points[activeIndex][points[activeIndex].length - 2]);
    }
  };

  const onSave = () => {
    let allVariables = {}
    allVariables["id"] = id;
    let allPartsObj = transformArraytoObject(allParts, "variable")
    Object.keys(allPartsObj).forEach(key => {
      if(key.indexOf("_only")==-1){
        if(key.indexOf("_overlapped")==-1){
          // if(predictionOfAll[key].indexOf("https://")==-1){
            allVariables[key] = create_data_url_from_image_object(predictionOfAll[key])
          // }
        }else{
          if(key in onTopSeg){
            allVariables[key] = create_data_url_from_image_object(onTopSeg[key])
          }else{
            if(person_data && person_data[TABLE][0][key]){
            allVariables[key] = person_data[TABLE][0][key]
            }
            else{
              allVariables[key] = null
            }
          }
        }
      }
    })
    allVariables["name"] = props.name;
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    allVariables["prediction_type"] = props.loadImage;
    console.log("all  variables", allVariables)
    save_all({
      variables: allVariables,
    });
  }

  const createOptions = () => {
    let options = []
    // options.push({ value: "rgba(0,0,0,255)", label: "Rest" })
    let optionsAllParts = allParts.filter(
      part => part["variable"] != activeVariable && 
      part["variable"] !== "background_seg" &&
      !part["variable"].includes("_overlapped") &&
      !part["variable"].includes("_only")
    )
    optionsAllParts.forEach(optionPart => {
      options.push({ value: optionPart["variable"], label: optionPart["name"] })
    })
    return options
  }

  const deleteSegOne = () => {
    let allVariables = {}
    allVariables["id"] = id;
    let allPartsObj = transformArraytoObject(allParts, "variable")
    Object.keys(allPartsObj).map(part => {
      allVariables[part] = person_data[TABLE][0][part]
    })
    allVariables[activeVariable] = null;
    allVariables["name"] = props.name;
    allVariables["seconds_spent"] = moment().diff(initTime, "seconds");
    allVariables["prediction_type"] = props.loadImage;
    save_all({
      variables: allVariables,
    });
  }

  const deleteSeg = (activeVariable) => {
    return (
      <div>
        <Button className="" onClick={deleteSegOne}>
          Delete Previous segmentation
        </Button>
      </div>
    )
  }

  if (allLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div style={{ display: "flex" }}>
        <WorkArea
          url={props.url}
          workingCanvasRef={workingCanvasRef}
          capturePoint={capturePoint}
          helperCanvas={helperCanvas}
          onlySegCanvas={onlySegCanvas}
          activeVariable={activeVariable}
          backgroundCanvas={backgroundCanvas}
          backgroundClick={backgroundClick}
          overlappedSegCanvas = {overlappedSegCanvas}
          opacity={opacity}
        />
        <Controls
          allParts={allParts}
          renderButtons={renderButtons}
          addAnotherPath={addAnotherPath}
          person_data={person_data}
          TABLE={TABLE}
          activeVariable={activeVariable}
          mode={mode}
          lastPointClick={lastPointClick}
          points={points}
          activeIndex={activeIndex}
          deletePointClick={deletePointClick}
          PRED_GOOD_AVAILABLE={PRED_GOOD_AVAILABLE}
          setMode={setMode}
          onBackgroundClick={onBackgroundClick}
          onSave={onSave}
          opacity={opacity}
          setOpacity={setOpacity}
          nextImage={nextImage}
          fullViewRef={fullViewRef}
          lastPoint={lastPoint}
          setIgnorePartActive={setIgnorePartActive}
          allOptions={createOptions()}
          ignorePart={ignorePart}
          setIgnorePart={setIgnorePart}
          deleteSeg={deleteSeg}
          deleteSegOne={deleteSegOne}
        />
      </div>
      <Review
        url={props.url}
        fullRef={fullRef}
        overlapRegionCanvas={overlapRegionCanvas}
        opacity={opacity}
        segmentationsRegionCanvas={segmentationsRegionCanvas}
      />
    </div>
  )
}

export default MapPoints;