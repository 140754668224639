import React from 'react';

export default function Review(props) {

    const { fullRef, overlapRegionCanvas, opacity, segmentationsRegionCanvas } = props;

    return (<div style={{display: "flex" }}>
        <div class="outer">
            <div class="person_image">
                <img
                    height="800px"
                    width="600px"
                    src={
                        props.url
                    }
                />
            </div>
            <div class="canvas_1">
                <canvas
                    height="800px"
                    width="600px"
                    ref={fullRef}
                    style={{opacity: `${opacity* 0.1}`}}
                />
            </div>
        </div>
        <div class="outer">
            <div class="person_image">
                <img
                    height="800px"
                    width="600px"
                    src={
                        props.url
                    }
                />
            </div>
            <div class="overlap_region">
                <canvas
                    height="800px"
                    width="600px"
                    ref={overlapRegionCanvas}
                    style={{opacity: `${opacity* 0.1}`}}
                />
            </div>
            <div class="segmentations_region">
                <canvas
                    height="800px"
                    width="600px"
                    ref={segmentationsRegionCanvas}
                    style={{opacity: `${opacity* 0.1}`}}
                />
            </div>
        </div>
    </div>)
}