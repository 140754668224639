import React from 'react';

export default function WorkArea(props) {

    const {workingCanvasRef, capturePoint, helperCanvas, onlySegCanvas, activeVariable, backgroundCanvas, backgroundClick, overlappedSegCanvas, opacity} = props;

    return (
        <div>
            <div class="outer">
                <div class="person_image"> 
                    <img
                        height="800px"
                        width="600px"
                        src={
                            props.url
                        }
                    />
                </div>
                <div class="canvas_1"> 
                    <canvas 
                        height="800px"
                        width="600px"
                        ref={workingCanvasRef} 
                        onClick={capturePoint} 
                        // style={{opacity: `${opacity* 0.1}`}}
                    />
                </div>
                <div class="canvas_2"> 
                    <canvas 
                        height="800px"
                        width="600px"
                        ref={helperCanvas}
                    />
                </div>
                {activeVariable.indexOf("_only")!==-1 && <div class="only_seg_canvas"> 
                    <canvas 
                        height="800px"
                        width="600px"
                        ref={onlySegCanvas}
                        onClick={capturePoint}
                        style={{opacity: `${opacity* 0.1}`}}
                    />
                </div>}
                {backgroundClick && <div class="only_seg_canvas"> 
                    <canvas 
                        height="800px"
                        width="600px"
                        ref={backgroundCanvas}
                        onClick={capturePoint}
                        style={{opacity: `${opacity* 0.1}`}}
                    />
                </div>}
                {activeVariable.indexOf("_overlapped")!==-1 && <div class="only_seg_canvas"> 
                    <canvas 
                        height="800px"
                        width="600px"
                        ref={overlappedSegCanvas}
                        onClick={capturePoint}
                        style={{opacity: `${opacity* 0.1}`}}
                    />
                </div>}
            </div>
        </div>
    )
}